.alum-scroll-wrap{
  transition: var(--primary-transition);
}

.alumini-section {
  padding: 4rem 0 2rem;
}

.alumini-section .wit-requirement-hero {
  padding-bottom: 30px;
}

.wit-ctrls {
  display: flex;
  width: 100%;
  height: 150px;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 50px;
  /* margin: 100px auto 40px; */
}

.alumini-year {
  font-family: GeneralSans;
  font-size: 200px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;
  color: var(--header-text-color);
  padding: 30px;
  cursor: pointer;
  transition: var(--secondary-transition);
}

.alumini-year:not(.active):hover{
  background-color: #CED3DD;
  transition: var(--secondary-transition);
}

.alum-year-wrap {
  border-top: 2px solid rgba(2, 7, 17, 1);
  border-bottom: 2px solid rgba(2, 7, 17, 1);
}

.alumini-year.active {
  font-size: 84px;
}

.profile-card-wrap {
  justify-content: center;
}

.profile-card-wrap {
  gap: 30px;
  /* transition: var(--primary-transition); */
}

.width-container {
  max-width: 1400px;
  width: 100%;
  margin: auto;

}

/* .dynamic-bg {
  background-color: var(--lavender-alt);
} */

.profile-card-list-wrap {
  position: relative;
  margin-top: 40px;
}

.wit-scroller .profile-card:first-child {
  margin-left: auto;
}

.wit-scroller .profile-card:last-child {
  margin-right: auto;
}

.alum-year-wrap .wit-scroller {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.for-mobile {
  display: none;
}

.for-desktop {
  display: block;
}

.alum-year-wrap .wit-scroller{
  overflow-x: scroll;
  scrollbar-width: none;
}

.for-mobile .alum-year-wrap .wit-scroller{
  justify-content: flex-start!important;
}

@media screen and (max-width:768px) {
  .for-mobile {
    display: block;
  }

  .wit-ctrls {
    display: none !important;
  }

  .alum-year-wrap {
    border-top: none;
  }

  .alumini-year {
    font-size: 60px;
  }

  
  .wit-profile-desc {
    display: none;
  }

  .alum-year-wrap.show-all .wit-scroller {
    flex-direction: column;
    gap: 0;
    align-items: center;
    /* padding: 0; */
  }

  .wit-scroller .profile-card:first-child {
    margin-left: 0;
  }

  .wit-scroller .profile-card:last-child {
    margin-right: 0;
  }

  .for-desktop {
    display: none;
  }
}