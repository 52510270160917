.wit-about-intro-sect {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}

.wit-section-container {
  margin: auto;
}

.abt-bg-container {
  opacity: 0.8;
}

.abt-deco {
  position: absolute;
}

.abt-deco.tl1 {
  top: 10rem;
  left: 22rem;
}

.abt-deco.tl2 {
  top: 18rem;
  left: 6rem;
}

.abt-deco.tl3 {
  bottom: 2rem;
  left: 4rem;
}

.abt-deco.tr1 {
  top: 6rem;
  right: 6rem;
}

.abt-deco.tr2 {
  bottom: 15rem;
  right: 10rem;
}

.abt-deco.tr3 {
  bottom: 2rem;
  right: 20rem;
  width: 120px;
}

.about-nav {
  padding: 16px 54px;

}

.wit-about-div {
  position: relative;
}

.about-descr {
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2;
}


.about-descr p {
  width: 75%;
  margin: auto;
  font-family: GeneralSans;
  font-size: 73px;
  font-weight: 600;
  line-height: 79px;
  letter-spacing: 0em;
  text-align: center;
}

.about-intro {
  /* background: url("../../../../common/assets/img/about-bg.png");
  background-repeat: no-repeat;
  background-position: left; */
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

@media screen and (max-width:1000px) {
  .about-descr p {
    width: 100%;
  }
}

@media screen and (max-width: 768px) and (orientation: portrait),
screen and (max-width: 1024px) and (orientation: landscape) {
  .abt-deco.tl1 {
    top: 9rem;
    left: 9rem;
  }

  .abt-deco.tl2 {
    left: 2rem;
  }

  .abt-deco.tr2 {
    right: 0;
  }

  .abt-deco.tl3,
  .abt-deco.tr1 {
    display: none;
  }

  .abt-deco.tr3 {
    right: 2rem;
  }

  .about-descr p{
    font-size: 50px;
    line-height: 50px;
    width: 75%;
  }
}

@media screen and (max-width:767px) {
  .about-intro {
    background-image: url("../../../../common/assets/img/about-bg-sm.png");
    background-position: top;
    background-size: contain;
  }

  .abt-bg-container {
    display: none;
  }

  .about-descr p {
    font-size: 30px;
    line-height: 40px;
    width: 90%;
  }
}