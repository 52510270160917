.home-wrap {
  height: 100vh;
  max-width: 100vw;
  font-family: GeneralSans;
  overflow: hidden;
}

.home-nav-wrapper{
  font-family: Inter;
}

.zoomed .wit-wrap {
  display: none;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.background-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: transform 1s ease;
  object-fit: fill;
}

.zoomed.background-container img {
  transform: translate(20%, -290%) scale(11);
}

.home-wrap .home-content{
  min-height: 95vh;
}

.wit-home-nav {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}

.wit-home-nav span {
  padding: 4px 5px 2px;
  border: 1px solid transparent;
}

.wit-home-nav ul li {
  padding: 6px 10px;
  text-transform: uppercase;
  /* font-family: founder-grotesk, sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  font-family: Inter;
}

ul {
  padding: 0;
}

ul li {
  margin: 0;
  list-style: none;
}

nav {
  /* font-family: founder-grotesk, sans-serif; */
}

.home-link {
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;
}

.text-group {
  font-family: GeneralSans;
}

.text-group .search {
  font-size: 36px;
  line-height: 100%;
  white-space: nowrap;
  letter-spacing: 0.22px;
}

.text-group .dream {
  font-size: 104px;
  font-weight: 900;
  line-height: 100%;
  margin: 0;
  text-shadow:
      -2px -2px 0 var(--black),
      2px -2px 0 var(--black),
      -2px 2px 0 var(--black),
      2px 2px 0 var(--black),
      0px 9px 0 var(--black);
}

.text-group .is-on {
  font-size: 36px;
  font-weight: 500;
  line-height: 100%;
}

.text-group .wit-5 {
  font-size: 250px;
  line-height: 60%;
  text-shadow:
  -2px -2px 0 var(--black),
  2px -2px 0 var(--black),
  -2px 2px 0 var(--black),
  2px 2px 0 var(--black),
  0px 12px 0 var(--black);
}

.section-wrap {
  padding: 3rem 0;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 60vh;
}

.home-hero {
  gap: 100px;
  margin: 0 auto 40px;
}

.time-counter {
  gap: 16px;
}

.wit-scroll {
  width: 26px;
  height: 42px;
  border: 1px solid white;
  border-radius: 30px;
  margin: auto;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.abt-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 100%; */
  align-items: center;
}
.abt-wrapper .apply-now{
  width: 100%!important;
}

@media screen and (max-width: 1050px) {
  .background-container img{
    object-fit: cover;
  }
  .apply-now {
    margin-top: 20px!important;
  }

  .wit-home-nav ul{
    overflow-x: scroll;
    white-space: nowrap;
  }

  .text-group .dream {
    font-size: 80px;
  }

  .text-group .wit-5 {
    font-size: 200px;
    line-height: 60%;
    text-shadow:
      -2px -2px 0 var(--black),
      2px -2px 0 var(--black),
      -2px 2px 0 var(--black),
      2px 2px 0 var(--black),
      0px 8px 0 var(--black);
    /* text-shadow: 0px 8px 0px var(--black); */
  }

  .text-group .is-on,
  .text-group .search {
    font-size: 34px;
  }
  .home-hero{
    margin: 0 auto 10px;
    gap: 50px;
  }
}
@media screen and (max-width: 900px) {
  .text-group .dream {
    font-size: 65px;
  }

  .text-group .wit-5 {
    font-size: 100px;
  }

  .text-group .search {
    white-space: wrap;
    font-size: 26px;
  }

  .text-group .is-on {
    font-size: 35px;
  }
  .abt-wrapper{
  gap: 20px;
  }
}
@media screen and (max-width: 797px) {
  .section-wrap{
    padding: 1.5rem 0;
  }
  .home-nav-wrapper{
    padding: 0;
  }

  .text-group .dream {
    font-size: 55px;
  }

  .text-group .wit-5 {
    font-size: 120px;
  }

  .text-group .search {
    font-size: 21px;
  }

  .text-group .is-on {
    font-size: 35px;
  }
  .time-counter{
    justify-content: center;
    margin: 20px auto 30px;
  }
  .zoomed.background-container img {
    transform: translate(55%, -300%) scale(11);
  }
  
}

@media screen and (max-width: 380px) {

  .text-group .dream {
    font-size: 50px;
  }

 

  .text-group .search {
    white-space: wrap;
    font-size: 20px;
  }


  .text-group .is-on {
    font-size: 30px;
  }
  .time-counter{
    justify-content: center;
    margin: 10px auto;
  }
  .apply-now {
    margin-top: 0!important;
  }
  .abt-wrapper{
    gap: 8px;
  }
  .hero-section{
    padding-top: 40px;
  }
.scroll-down{
  margin-top: 14px!important;
}
}

@media screen and  (max-width: 500px){
  .background-container img{
    object-fit: cover;
  }
  .zoomed.background-container img {
    transform: translate(60%, -250%) scale(8);
  }
  .abt-wrapper{
    padding-top: 2rem;
  }
}

@media screen and (max-width: 950px) and (orientation: landscape){
  .abt-wrapper,
  .hero-section{
    padding-top: 0;
  }
  .home-wrap{
    overflow: auto;
    height: 100%;
  }
}