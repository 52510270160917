.about-slide {
  gap: 10px;
}

.about-card {
  flex-direction: column;
  gap: 30px;
  flex: 1;
  min-width: fit-content;
  max-width: fit-content;
  border-radius: 30px;
  height: 676px;
  justify-content: space-between;
  border: none;
  position: relative;
}

.about-card h1 {
  font-family: aventa;
  font-size: 80px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 0em;
  margin-bottom: 16px;
}

.about-card p {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  color: rgba(9, 34, 86, 1);
}

.wit-women img,
.wit-card-bottom-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-card .wit-card-text{
  padding: 24px;
}

.about-card .wit-card-text h1 {
  font-weight: 600;
  font-family: GeneralSans;
}

.wit-women {
  background: rgba(243, 251, 224, 1);
}

.wit-women h1 {
  color: rgba(13, 60, 51, 1);
}

.wit-women p {
  max-width: 360px;
}

.wit-team {
  background: rgba(247, 243, 254, 1);
  gap: 0;
}

.wit-team .wit-card-text {
  padding-left: 48px;
  padding-right: 48px;
}

.wit-team p {
  max-width: 500px;
}

/* .wit-leap {
  min-width: 444px;
  max-width: 444px;
  min-height: 650px;
  
  background-color: var(--blue);
  display: flex;
} */
.wit-leap {
  background: rgba(3, 87, 238, 1);
  overflow: hidden;
  background-image: url("../../../../../common/assets/img/leap-bg-pattern.png");
  background-position: bottom;
  background-repeat: no-repeat;
}

.wit-card-group {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 30px;
  height: 676px;
  /* gap: 10px; */
}

.wit-card-group .about-card {
  flex: 1;
  min-width: 680px;
  /* max-height: 306px; */
  height: 50%;

}

.wit-card-group-img {
  position: absolute;
  right: 5%;
  top: 10px;
}

.wit-card-group-img img {
  width: 100%;
}


.wit-card-bottom-img {
  bottom: -40px;
  right: -15px;
  top: unset;
}

/* .wit-card-top-img {
  top: -20px;
  right: -40px;
  transform: rotate(-60deg);
} */
.wit-card-group .wit-card-text {
  max-width: 560px;
}

.perks {
  margin-top: 20px;
}

.perks>div {
  flex: 1;
}

.perks p {
  margin-bottom: 8px;

}

.perks-bonus {
  font-size: 16px !important;
  width: 90%;
  margin-top: 30px;

}

.wit-card-group .about-card .wit-card-text h1,
.wit-leap .wit-card-text h1 {
  font-size: 64px;
}

.wit-duration {
  background: rgba(238, 249, 254, 1);
}

.wit-duration .wit-card-text{
  color: rgba(9, 34, 86, 1);
}

.wit-perks {
  background: rgba(255, 242, 235, 1);
}

.wit-perks h1 {
  color: rgba(79, 26, 28, 1);
}

.wit-perks .wit-card-text {
  width: 90%
}

.wit-leap {
  max-width: 444px;
  min-width: 444px;
  width: 100%;
  padding-top: 40px;
}

.wit-img-group {
  max-width: 760px;
  flex-wrap: wrap-reverse;
  justify-content: center;
}

.wit-img-group img {
  width: 20%;
}

.real-projects {
  color: var(--blue);
  font-weight: 600;
}

.perks .d-flex {
  gap: 5px;
}

@media screen and (max-width:768px) {
  .about-scroll-wrap>div {
    flex: 1;
    max-width: 700px;
    width: 100%;
  }

  .about-scroll-wrap>div .wit-card-text {
    max-width: unset;
  }

  .about-card {
    height: fit-content;
    min-width: 300px !important;
  }

  .about-card h1 {
    font-size: 40px !important;
  }

  .wit-card-bottom-img img {
    width: 80%;
    height: unset;
    object-fit: contain;
  }

  .wit-card-bottom-img {
    right: -4rem;
    bottom: -10px;
  }

  .wit-team .wit-card-text {
    padding: 24px;
  }

  .wit-img-group img {
    width: 50%;
  }

  .wit-perks .wit-card-text {
    width: 100%
  }

  .wit-duration .wit-card-top-img img {
    width: 70%;
  }

  .wit-duration .wit-card-top-img {
    right: -35px;
  }

  .perks-bonus {
    width: 75%;
  }

  .wit-leap {
    min-height: 405px;
  }
}

@media screen and (max-width:768px) {
  .wit-img-group{
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media screen and (min-width:300px) and (max-width:768px) {
  .about-scroll-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width:328px) {
  .wit-card-group,
  .wit-card-group .wit-card-text,
  .wit-card-group .about-card {
    min-width: 200px !important;
    flex-wrap: wrap;
  }

  .about-card .wit-card-text {
    padding: 20px 10px;
  }

  .wit-card-group .wit-card-text>div {
    flex-wrap: wrap;
  }
}