/* faqs */
.faq-accordion-wrap {
  background-image: url("../../../../common/assets/img/faqbg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75%;
}

.faq-container {
  max-width: 1080px;
  width: 100%;
  position: relative;
}

.faq-deco{
  position: absolute;
}

.faq-deco.left-img{
  left: 8px;
  bottom: 20%;
  width: 7%;
}

.faq-deco.right-img{
  right: -8px;
  top: 50%;
  width: 10%;
}

.faq-accordion {
  border: 1px solid var(--black) !important;
}

.faq-accordion .accordion-button:not(.collapsed)::after {
  background-image: url("../../../../common/assets/icons/minus.svg");
  transform: unset;
}

.faq-heading {
  color: var(--header-text-color);
  font-weight: 700;
  font-size: 84px;
  line-height: 100%;
  font-family: GeneralSans;
}

.faq-accordion .accordion-button::after {
  background-image: url("../../../../common/assets/icons/plus.svg");
  transform: unset;
}
.accordion-button b{
  font-family: GeneralSans-Medium;
  font-size: 20px;
  font-weight: 500;
}


/* secret section */
.secret-text-group {
  margin: auto;
  padding: 20px;

}

.secret-text-group .wit-small-text {
  max-width: 350px;
  vertical-align: bottom;
  font-weight: 400;
  font-size: 16.5px;
}

.secret-text-group .big-text {
  font-family: GeneralSans;
  font-style: normal;
  font-weight: 600;
  display: inline-block;
  font-size: 48px;
  max-width: 500px;
  width: 100%;
  line-height: 100%;
  color: var(--header-text-color);
}

.wit-secret {
  max-width: 940px;
  width: 100%;
  min-height: 300px;
  background: #CDDDFC;
  border: 1.5px solid var(--black);
  box-shadow: 0px 19.3729px 21.9559px rgba(0, 41, 114, 0.15);
  border-radius: 0px 64px;
}

@media screen and (min-width:800px) {
  .secret-text-group .wit-small-text{
    margin-left: -150px;
  }
  .secret-text-group .big-text{
    font-size: 72px;
  }
}