:root {
  --blue: #0357EE;
  --blue-alt: #023DA7;
  --sky-blue-alt: #05C2FF;
  --yellow: #FED75B;
  --yellow-alt: #FFF7DE;
  --white: #ffffff;
  --black: #000000;
  --dark-blue: #092256;
  --sky-blue: #F1F7FE;
  --pink: #F9D6CD;
  --orange: #FF824C;
  --grey: #F3F4F7;
  --primary-transition: all .8s .4s;
  --secondary-transition: all .3s ease-in 5ms;
  --animate-delay: 0.5s;
  --lavender: rgba(228, 214, 253, 1);
  --turquoise: rgba(196, 233, 253, 1);
  --lite-lemon: rgba(214, 242, 147, 1);
  --rainforest: rgba(13, 60, 51, 1);
  --lite-peach: rgba(255, 224, 204, 1);
  --lite-mint: rgb(226, 255, 246);
  --lite-almond: rgb(255, 241, 198);
  --header-text-color: #08080C;
  --lite-lemon-text-color: #99AF6E;
  --lite-peach-text-color: #4F1A1C;
  --mint-text-color: #99B8B2;
  --turquoise-text-color: #8CA8BA;
  --lavender-text-color: #A39BBA;
}

* {
  box-sizing: border-box;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  scrollbar-width: none;
  overflow-x: hidden;
}


@font-face {
  font-family: aventa;
  src: url("../src/common/assets/fonts/Aventa/Aventa-Bold.woff");
  font-weight: 900;
}

@font-face {
  font-family: founder-grotesk;
  src: url("../src/common/assets/fonts/Founders_Grotesk/FoundersGrotesk-Semibold.otf");
  font-weight: 600;
}

@font-face {
  font-family: founder-grotesk-md;
  src: url("../src/common/assets/fonts/Founders_Grotesk/FoundersGrotesk-Medium.otf");
  font-weight: 600;
}

@font-face {
  font-family: founder-grotesk-light;
  src: url("../src/common/assets/fonts/Founders_Grotesk/FoundersGrotesk-Light.otf");
}

@font-face {
  font-family: Inter;
  src: url("../src/common/assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf");
}

@font-face {
  font-family: GeneralSans;
  src: url("../src/common/assets/fonts/GeneralSans/GeneralSans-Semibold.otf");
}

@font-face {
  font-family: GeneralSans-Medium;
  src: url("../src/common/assets/fonts/GeneralSans/GeneralSans-Medium.otf");
}
