/*  footer */
.wit-footer {
  background-color: var(--blue);
  margin: 0;
  color: white;
}

.wit-footer .policy a{
  color: white;
}

.wit-footer .wit-scroll {
  border-color: var(--white);
}

.wit-footer .social-icons li {
  padding-right: 16px;
}

.wit-footer img {
  cursor: pointer;
}

.wit-order-2 {
  gap: 30px;
}


.wit-footer .policy {
  gap: 45px;
}

@media screen and (max-width:328px) {
  .wit-footer .policy {
    display: none !important;
  }
}

@media screen and (max-width: 500px) {
  .wit-footer-wrap{
    justify-content: center!important;
    gap: 12px;
    font-size: 12px;
  }
  .wit-footer-wrap img{
    width: 90%;
  }
  .policy a{
    white-space: nowrap;
  }
  .wit-footer .social-icons li {
    padding-right: 4px;
  }
}
