.alum-header {
  padding: 24px;
}

.alum-toggle-btn-wrap {
  max-width: 137px;
  width: 100%;
}

.mb-alum-title {
  font-family: Aventa;
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--rainforest);

}

.mb-alumini-year {
  font-family: GeneralSans;
  font-size: 72px;
  font-weight: 400;
  line-height: 72px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--header-text-color);
}

.alum-year-wrapper{
  overflow: hidden;
}
