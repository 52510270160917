.profile-card {
  border: 1.5px solid var(--black);
  box-shadow: 0px 11.25px 12.75px rgba(0, 41, 114, 0.15);
  border-radius: 18px;
  cursor: pointer;
  max-width: 600px;
  min-width: 600px;
  min-height: 300px;
  height: 100%;
  font-family: GeneralSans-Medium;
  font-weight: 400;
  color: var(--header-text-color);
}

.profile-card-wrap .profile-card:not(.profile-collapsed) .profile-img {
  width: 80%;
  border-radius: 80px 80px 80px 0px;
  max-width: 200px;
}

.wit-profile-desc .card-body {
  transition: var(--primary-transition);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  max-width: 608px;
}

.wit-name-wrap {
  font-size: 24px;
  padding-top: 30px;

}

.one-word {
  line-height: 24px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  display: table-caption;
  display: -ms-grid;
  -ms-grid-columns: min-content;
}

.profile-collapsed {
  min-width: 300px !important;
}

.profile-card .wit-profile-desc,
.profile-card .wit-position {
  display: block;
}

.profile-collapsed .wit-profile-desc,
.profile-collapsed .wit-position {
  display: none !important;
}



.profile-collapsed:hover .plus-icon,
.profile-card .plus-icon {
  transform: rotate(40deg);
  transition: var(--secondary-transition);
  font-size: 50px;
}

.profile-img {
  transition: var(--secondary-transition);
  max-height: 209px;
}

.profile-collapsed:hover .profile-img {
  transform: scale(1.01);
  transition: var(--secondary-transition);
  box-shadow: 0px 11.25px 12.75px rgba(0, 41, 114, 0.15);

}

.profile-collapsed .plus-icon {
  transform: rotate(0);
}

.profile-card .plus-icon {
  position: absolute;
  bottom: -5px;
  right: 5%;
}

.profile-card .wit-position {
  padding-left: 1rem;
  padding-bottom: 1rem;
  color: var(--blue);
  bottom: 0;
  font-size: 20px;
  line-height: 20px;
  font-family: GeneralSans-Medium;
}

.wit-profile-mint .plus-icon{
  color: rgba(123, 149, 146, 1);
}

.wit-profile-lite-lemon .plus-icon {
  color: var(--lite-lemon);
}

.wit-profile-name p {
  text-transform: capitalize;
  font-family: founder-grotesk-md;
}

.wit-profile-lite-lemon .profile-img {
  background-color: var(--lite-lemon);
  border-radius: 0px 90px;
}

.wit-profile-lite-peach .plus-icon{
  color: var(--lite-peach);
}

.wit-profile-lavender .plus-icon {
  color: var(--lavender);
}

.wit-profile-mint .profile-img, 
.wit-profile-lavender .profile-img {
  border-radius: 90px 0px 90px 90px;
}

.wit-profile-turquoise .plus-icon {
  color: var(--turquoise);
}

.wit-profile-turquoise .profile-img {
  border-radius: 90px 0 0 0;
}

.wit-profile-lite-peach .profile-img {
  border-radius: 0px 90px 90px 90px;
}

.wit-profile-orange .plus-icon {
  color: var(--orange);
}

.wit-profile-lite-almond .profile-img {
  border-radius: 90px 0px 0px 0px;
}

.wit-profile-lite-lemon .wit-position{
  color: var(--lite-lemon-text-color);
}

.wit-profile-mint .wit-position{
  color: var(--mint-text-color);
}

.wit-profile-lite-peach .wit-position{
  color: var(--lite-peach-text-color);
}

.wit-profile-turquoise .wit-position{
  color: var(--turquoise-text-color);
}

.wit-profile-lavender .wit-position{
  color: var(--lavender-text-color);
}

.active-profile .profile-img {
  max-width: 124px;
}
.profile-collapsed .profile-img {
  width: 100% !important;
  min-height: 150px;
  min-width: 200px;
}

.profile-img {
  overflow: hidden;
}

.profile-img img {
  object-fit: contain;
}

.wit-profile-cont {
  padding: 1rem;
}

.mobile-profile-view.hide {
  display: none;
}

.mobile-profile-view.show {
  display: flex;
  width: 100vw;
  height: 100%;
}

.mobile-profile-view {
  display: none;
}

.for-desktop .wit-profile-turquoise{
  min-height: 320px;
}

.for-desktop .wit-profile-lite-lemon{
  min-height: 340px;
}

.for-desktop .profile-collapsed .one-word{
  position: absolute;
  bottom: 10px;
}

@media screen and (max-width:768px) {

  .profile-card {
    min-width: 280px;
    min-height: fit-content;
  }

  .profile-card:not(.profile-collapsed) .wit-profile-cont {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .profile-card-list-wrap .wit-scroller {
    display: flex;
    flex-wrap: nowrap;
  }

  .mobile-profile-view {
    position: fixed;
    min-height: 100vh;
    background-color: rgba(153, 153, 153, 1);
    width: 100%;
    backdrop-filter: blur(15px);
    align-items: center;
    justify-content: center;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .profile-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    width: 90%;
    margin: auto;
    border-radius: 17.55px;
    border: 1px solid black;
  }

  .mobile-profile-view .profile-card {
    height: 60%;
    max-width: 320px;
    width: 100%;
    min-height: unset;
    min-width: unset;
    transition: all ease-in 1s;

  }

  .mobile-profile-view .profile-img {
    border-radius: 80px 80px 80px 0px;
  }
  .profile-modal .profile-img{
    max-width: 100px;
  }
}