p{
  margin: 0;
}
.time-card {
  min-width: 115px;
  max-width: 122px;
  width: 100%;
  min-height: 177px;
  max-height: 177px;
  height: 100%;
  text-align: center;
  background: var(--blue-alt);
  box-shadow: 0px 6px 0px var(--black);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-weight: 700;
  padding: 16px 0;
  gap: 10px;
}

.time-card .time-value {
  font-size: 48px;
  line-height: 100%;
  /* margin-top: 24px; */
}

.time-card .title {
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  text-transform: capitalize;
}

@media screen and (max-width:600px) {
  .time-card {
    min-height: 100px;
    max-width: 93px;
    min-width: 70px;
    border-radius: 16px;
  }

  .time-card .time-value {
    font-size: 30px;
  }
  .time-card .title{
    font-size: 15px;
  }
}
@media screen and (max-width:380px) {
  .time-card {
    min-height: 2rem;
  }
  .time-card .time-value {
    font-size: 20px;
  }
 
}