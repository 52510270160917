
.btn-wit-apply {
  max-width: 257px;
  width: 100%;
  height: 60px;
  background: #FFEBAF;
  border: 1.5px solid var(--black);
  box-shadow: 0px 11px 0px var(--black);
  border-radius: 60px;
  transition: var(--secondary-transition);
}

.btn-wit-apply:hover {
  background: var(--yellow);
  transition: var(--secondary-transition);
}

.btn-wit-apply span{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}